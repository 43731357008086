
.special[data-v-62f3003b]{
    height: 132px;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFFB2;
    padding: 16px;
    gap: 16px;
    margin-bottom: 16px;
}
.classificationLable[data-v-62f3003b]{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding-right: 8px;
  display: flex;
}
.icon-left[data-v-62f3003b]{
  display: inline-block;
  width: 2px;
  height: 16px;
  background-color: #2047D3;
  margin-right: 8px;
}
.classificationText[data-v-62f3003b]{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
}
.specialBox[data-v-62f3003b]{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}
