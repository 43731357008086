
.alertClass[data-v-9587de06]{
  margin-bottom: 8px;
}
.message[data-v-9587de06]{
  width: 100%;
  text-align: center;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #1D2129;
  padding: 24px;
}
