
.textHead[data-v-4eede4f4]{
  width: 1080px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
.table[data-v-4eede4f4]{
  width: 100%;
  font-family: PingFang SC;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
}
.data[data-v-4eede4f4]{
  width: 100%;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.specialShowMain[data-v-4eede4f4]{
  position: relative;
  background: #FFFFFFB2;
  border: 1px solid #FFFFFF;
  padding: 16px;
  border-radius: 2px;
  margin-top: -104px;
  z-index: 10;
  backdrop-filter: blur(20px);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 16px;
  min-height: calc(100vh - 352px);
}
.specialShowList[data-v-4eede4f4]{
  //position: absolute;
  //top: -104px;
}
