
body[data-v-20d7c30c] {
    background: #fff !important;
}
[data-v-20d7c30c] .el-drawer__header {
    border-bottom: solid 1px lightgray;
    padding: 10px;
    margin-bottom: 0;
}
.active-tab[data-v-20d7c30c] {
    border-radius: 6px;
    background: #e5e7eb;
    padding: 6px;
}
