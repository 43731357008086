
.pubic-main[data-v-ea31ff1f] {
  display: flex;
  width: 100%;
  height: 100%;
}
.login-pubic[data-v-ea31ff1f] {
  width: calc(100% - 560px);
  height: 100%;
  position: relative;
}
.backPublic[data-v-ea31ff1f] {
  width: 100%;
  height: 100%;
}
.from-box[data-v-ea31ff1f] {
  height: 100%;
  width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.messages[data-v-ea31ff1f] {
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #1D2129;
  margin-bottom: 32px;
  display: inline-block;
}
.backLogo[data-v-ea31ff1f]{
  position: absolute;
  top: 66px;
  left: 66px;
}
