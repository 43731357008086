
.codeInput[data-v-c768054b] .el-input-group__append {
  background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
  box-shadow: 1px 0 0 0 var(--el-input-border-color, var(--el-border-color)), 
            0 1px 0 0 var(--el-input-border-color, var(--el-border-color)), 
            0 -1px 0 0 var(--el-input-border-color, var(--el-border-color));
  padding: 0 8px;
  width: 130px;
}
.codeInput[data-v-c768054b] .el-input__wrapper {
  box-shadow: -1px 0 0 0 var(--el-input-border-color, var(--el-border-color)), 0 1px 0 0 var(--el-input-border-color, var(--el-border-color)), 0 -1px 0 0 var(--el-input-border-color, var(--el-border-color));
}
.getCodeButtom[data-v-c768054b] {
  
  text-align: center;
}
.demo-tabs[data-v-c768054b] .el-tabs__header {
  margin: 0 0 20px;
}
.codeInput[data-v-c768054b] .el-input-group__append button.el-button{
  color: #2047D3;
  /* border-color: #2047D3; */
  font-size: 16px;
}
.codeInput[data-v-c768054b] .el-input-group__append button.el-button:hover{
  color: #409EFF;
}
