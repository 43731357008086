
.tab-class[data-v-11494ad7]{
  //styleName: Small Text/CN-Regular;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.tab-class[data-v-11494ad7]:hover{
  font-weight: 600;
}
