
.configurInput[data-v-a57318d2] .el-input__wrapper {
  border: none;
}
.configurInput[data-v-a57318d2] .el-input-group__append .el-button.el-button--primary {
  background-color: #5872E5;
  border-color: #5872E5;
  color: #fff;
  font-size: 14px;
}
.system-logo-box[data-v-a57318d2] {
  background: #5872E5;
  cursor: pointer;
  display: inline-block;
  /* height: 50px; */
  padding: 13px;
}
.system-logo-box .system-logo[data-v-a57318d2] {
  height: 100%;
  width: auto;
}
.text-class[data-v-a57318d2] {
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
}
.configurForm[data-v-a57318d2] .el-form-item .el-form-item__content {
  display: block;
}
