
.inputBox[data-v-39070d8f] {
  width: 100%;
  height: 64px;
  padding: 16px 0;
  position: sticky;
  top: 56px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
}
.leftBox[data-v-39070d8f] {
  position: sticky;
  top: 72px;
  left: 0;
  width: 100%;
  height: calc(100vh - 176px);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.leftBox[data-v-39070d8f]::-webkit-scrollbar{
  display: none;
}
.searchInput[data-v-39070d8f] {
  height: 32px;
  width: 400px;
  --el-input-focus-border: #2047D3;
  --el-input-focus-border-color: #2047D3;
  --el-input-hover-border: #C9CDD4;
}
.searchInput[data-v-39070d8f]:focus {
  box-shadow: none;
  border-color: #2047D3;
}
.searchInput .el-input-group__append .el-button[data-v-39070d8f] {
  border: none !important;
  height: 30px !important;
  background-color: #2047D3 !important;
  color: #FFFFFF;
  font-weight: 400;
  border-radius: 0 4px 4px 0 !important;
}
.searchInput .el-input-group__append .el-button[data-v-39070d8f]:hover {
  background-color: #4369DC !important;
  color: #FFFFFF;
  border-radius: 0 4px 4px 0 !important;
}
.position[data-v-39070d8f] {
  position: relative;
}
.main-box[data-v-39070d8f] {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding-right: 8px;
  min-height: 448px;
}
.right-box[data-v-39070d8f] {
  width: calc(100% - 320px);
  padding-top: 0px;
}
.titleBox[data-v-39070d8f] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  position: sticky;
  top: 0px;
  background-color: #FDFDFD;
  z-index: 10;
}
.buttonBox[data-v-39070d8f] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 128px;
  height: 32px;
  //padding: 4px;
  //background-color: #F2F3F5;
  border-radius: 2px;
}
.buttonCardTitle[data-v-39070d8f] {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1D2129;
  display: flex;
  align-items: center;
}
.buttonBoxIcon[data-v-39070d8f] {
  height: 16px;
  width: 2px;
  background-color: #2047D3;
  display: inline-block;
  margin-right: 8px;
}
.tabButton[data-v-39070d8f] {
  width: 66px;
  height: 32px;
  border-radius: 2px;
  display: inline-block;
  font-family: PingFang SC;
  background-color: #F2F3F5;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
.noAtivation[data-v-39070d8f]:hover {
  color: #2047D3;
}
.activation[data-v-39070d8f] {
  background-color: #2047D3;
  color: #FFFFFF;
}
.industrySidebar[data-v-39070d8f] {
  height: 36px;
  width: 100%;
  padding: 7px 8px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  cursor: pointer;
  color: #1D2129;
  border-radius: 4px;
}
.industrySidebar[data-v-39070d8f]:hover {
  background-color: #F7F8FA;
}
.activationIndustry[data-v-39070d8f] {
  background-color: #F2F3F5;
  color: #2047D3;
  font-weight: 600;
}
.popoveBox[data-v-39070d8f] {
  height: 300px;
  background-color: #F0F1FB;
}
.popoverMainBox[data-v-39070d8f] {
  height: 270px;
  padding: 16px;
  padding-bottom: 0;
}
.popoverBottmText[data-v-39070d8f] {
  height: 30px;
  background-color: #EDEBFD;
  color: #232631;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
}
.searchTips[data-v-39070d8f] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.searchTipsTag[data-v-39070d8f] {
  display: inline-block;
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  border: 1px solid #C9CDD4;
  border-radius: 2px;
  margin: 0 8px;
}
.leftTagBox[data-v-39070d8f] {
  height: calc(100vh - 127px);
}