
.el-dropdown[data-v-c7786592] {
  color: #303133;
}
.el-dropdown-link[data-v-c7786592] {
&[data-v-c7786592]:focus {
    outline: none;
}
}
.logoImg[data-v-c7786592] {
  padding: 15px 16px;
  height: 56px;
}
.tab[data-v-c7786592] {
  margin-left: 80px;
}
