
.tagItem[data-v-26f72fd0]{
  padding: 3px 8px;
  gap: 12px;
  border-radius: 4px ;
  border: 1px solid #C9CDD4;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #6B7785;
}
.formatTaskTags[data-v-26f72fd0]{
  width: 50%;
  height: 26px;
  /* margin: 16px 0; */
  display: flex;
  gap: 8px;
}
.popoverCard[data-v-26f72fd0] {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
