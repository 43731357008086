[data-v-0e4938da] .billing-table .el-table__empty-block {
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
}
[data-v-0e4938da] .billing-table .billing-table-header-row .billing-table-header-cell {
  padding: 12px 24px;
  font-size: 12px;
  font-weight: 400;
  color: var(--gray-600);
  border-top: 1px solid #eaecf0;
  background-color: #f9fafb;
}
[data-v-0e4938da] .billing-table .billing-table-header-row .billing-table-header-cell .cell {
  line-height: 18px;
}
[data-v-0e4938da] .billing-table .billing-table-row .billing-table-row-cell:nth-child(1) {
  border-left: 1px solid #eaecf0;
}
[data-v-0e4938da] .billing-table .billing-table-row .billing-table-row-cell:nth-last-child(1) {
  border-right: 1px solid #eaecf0;
}
[data-v-0e4938da] .billing-table .billing-table-row .billing-table-row-cell .cell {
  line-height: 40px;
}
