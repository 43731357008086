
.downloadButton[data-v-62e7beb0]{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #2047D3;
}
.filesTable[data-v-62e7beb0]{
  border: 1px solid #E5E6EB;
  margin-top: 16px;
}
.filesTable[data-v-62e7beb0] .warning-row {
  --el-table-tr-bg-color: #F2F3F5;
}
.filesTable[data-v-62e7beb0] .success-row {
  --el-table-tr-bg-color: #fff;
}
.filesTable[data-v-62e7beb0] .el-table__inner-wrapper .el-table__header-wrapper .el-table__header tr {
  background-color: #F2F3F5;
}
.filesTable[data-v-62e7beb0] .el-table__inner-wrapper .el-table__header-wrapper .el-table__header th {
  background-color: #F2F3F5;
}
.filesTable[data-v-62e7beb0] .el-table__inner-wrapper .el-table__header-wrapper .el-table__header th .cell {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 600;
  color: #6B7785;
}
.textClass[data-v-62e7beb0]{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1D2129;
}
