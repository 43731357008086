
.searchLiTag[data-v-a8339960]{
  width: 100%;
  height: 40px;
  padding: 4px 0;
  border-bottom: 1px solid  #E5E6EB;
}
.lable[data-v-a8339960]{
  color: #27254c;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
}
.lable[data-v-a8339960]:hover{
  color: #208DE7;
}
.text[data-v-a8339960]{
  color: #9c9da2;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
}
