
.speciaListTag[data-v-98cd7411]{
  height: 220px;
  padding: 16px;
  gap: 12px;
  border-radius: 2px;
  border: 1px solid #F2F3F5;
  box-shadow: 0px 2px 4px 0px #001C4D08;
  background: #FFFFFF;
}
.lableBox[data-v-98cd7411]{
  width: 100%;
  height: 108px;
  padding: 16px 20px;
  background: #5B8FF91A;
  box-shadow: 0px 0px 32px 0px #5B8FF933 inset;
}
.icon[data-v-98cd7411]{
  width: 16px;
  height: 16px;
  border-radius: 2px;
}
img[data-v-98cd7411]{
  width: 100%;
  height: 100%;
}
.leble[data-v-98cd7411]{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  width: calc(100% - 116px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.moreButton[data-v-98cd7411]{
  padding: 0 8px;
  height: 24px;
  background-color: #2047D3;
  border-radius: 2px;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.moreButton[data-v-98cd7411]:hover{
  background-color: #4369DC;
}
.moreButton[data-v-98cd7411]:focus{
  background-color: #1431B1;
}
.rightArrow[data-v-98cd7411]{
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
.topLableBox[data-v-98cd7411]{
  display: flex;
  gap: 8px;
  align-items: center;
}
.lebleBottom[data-v-98cd7411]{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1D2129;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottomBox[data-v-98cd7411]{
  margin-top: 12px;
}
.abstract[data-v-98cd7411]{
  display: flex;
  align-items: center;
}
.abstractIcon[data-v-98cd7411]{
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.abstractLable[data-v-98cd7411]{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1D2129;
}
.abstractText[data-v-98cd7411]{
  margin-top: 8px;
  margin-left: 20px;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6B7785;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
