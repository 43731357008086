
[data-v-f170c9b4] .el-input {
    height: 40px;
@media screen and (max-width: 768px) {
      width: 100%;
}
}
[data-v-f170c9b4] .el-radio__input {
    margin-top: 4px;
}
[data-v-f170c9b4] .el-radio__label {
    color: #344054 !important;
    font-weight: 400;
}
[data-v-f170c9b4] .el-radio.is-bordered.is-checked {
    border: 2px solid #3250bd;
}
[data-v-f170c9b4] .el-radio__input.is-checked .el-radio__inner {
    background: #3250bd;
    border-color: #3250bd;
}
[data-v-f170c9b4] .el-select {
    width: 240px;
    height: 40px;
@media screen and (max-width: 768px) {
      width: 100%;
}
}
[data-v-f170c9b4] .el-input .el-input__wrapper {
    border-radius: 8px;
}
