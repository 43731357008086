
.el-form-item[data-v-e69cf9fa] {
    margin-bottom: 24px;
}
.el-form-item__label[data-v-e69cf9fa] {
    margin-bottom: 6px;
}
[data-v-e69cf9fa] .el-textarea__inner {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 44px;
}
[data-v-e69cf9fa] .el-input__inner {
    height: 44px;
}
[data-v-e69cf9fa] .el-select__wrapper {
    height: 44px;
}
[data-v-e69cf9fa] .el-upload--picture {
    border: 0px;
}
[data-v-e69cf9fa] .hide .el-upload.el-upload--picture{
    display: none;
}
[data-v-e69cf9fa] .el-upload-list__item{
    transition: unset;
}
[data-v-e69cf9fa] .el-input-group__prepend {
    background-color: unset;
}
[data-v-e69cf9fa] .el-form-item .el-input__validateIcon {
    display: none;
}
