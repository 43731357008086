
body[data-v-2eb4df4a] {
    background: #fff !important;
}
[data-v-2eb4df4a] .el-drawer__header {
    border-bottom: solid 1px lightgray;
    padding: 10px;
    margin-bottom: 0;
}
.active-tab[data-v-2eb4df4a] {
    border-radius: 6px;
    background: #E5E7EB;
    padding: 6px;
}
