
.active-type[data-v-b9362b66] {
    box-shadow: 0px 0px 0px 4px rgba(152, 162, 179, 0.14);
    border-radius: 6px;
}
.label-h3[data-v-b9362b66]{
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #1D2129;
}
.TagsLable[data-v-b9362b66]{
    display: flex;
    justify-content: space-between;
}
.ExpandAndCollapse[data-v-b9362b66]{
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #6B7785;
    cursor: pointer;
}
.ExpandAndCollapse[data-v-b9362b66]:hover{
    color: #2047D3;
}
