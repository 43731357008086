
.dialogWidth {
  width: 30%;
}
@media (max-width: 640px) {
.dialogWidth {
    width: 80%;
}
}
@media (min-width: 641px) and (max-width: 1024px) {
.dialogWidth {
    width: 50%;
}
}
@media (min-width: 1025px) {
.dialogWidth {
    width: 30%;
}
}
