
[data-v-c310e999] .el-tabs__item.is-top.is-active{
  //styleName: H6/CN-Bold;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #323232;
  padding: 8px 16px !important;
  //gap: 15.5px;
}
[data-v-c310e999] .el-tabs__item.is-top{
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6B7785;
  padding: 8px 16px 8px 16px;
  padding-left: 16px!important;
  //gap: 15.5px;
}
[data-v-c310e999] .el-tabs__item.is-top:hover {
  color: #1D2129;
}
.modelTabs[data-v-c310e999]{
  --el-tabs-header-height:32px;
}

