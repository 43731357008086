
.specialTag[data-v-6cf5fea8]{
  width: calc((100% - 80px) / 6);
  height: 100%;
  padding: 16px;
  gap: 10px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}
.text[data-v-6cf5fea8]{
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
