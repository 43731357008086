#app {
    background: url("../images/background-img.png") no-repeat;
    background-size: 100% 100%;
}


.mian-min-h{
    min-height: calc(100vh - 144px);
}

.mian-h{
    height: calc(100vh - 144px);
}

.backcolor{
    background-color: transparent;
    width: 100%;
    height: 56px;
    margin-bottom: 16px;
}

.bg-white{
    background-color: transparent;
}

.tab-item{
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}
.tab-item:hover{
    color: #2047D3;
    opacity: 100%;
}
.tab-item-active{
    color: #2047D3;
    font-weight: 600;
}

.repoMain{
    background-color: #FFFFFFB2;
}

.popoverCss.el-popper.is-light{
    background: #F0F1FB;
    padding: 0;
}
.popoverCss .el-popper__arrow{
    display: none;
}
.navbarFixed{
    position:fixed;
    border-bottom: 1px solid #E5E6EB;
    width: 100%;
    top: 0;
    z-index: 88;
    /*position: relative;*/
}

.navbarBack{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
}
.newBox{
    width: 45px;
    height: 20px;
    background: url("../images/newImg.png") no-repeat;
    background-size: 100% 100%;
    font-family: DIN Alternate;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
}

.modelBack{
    width: 100%;
    height: 222px;
    background: url("../images/modelBack.png") no-repeat;
    background-size: 100% 100%;
    position:fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.mode-header{
    z-index: 10;
    position: sticky;
    top: 72px;
}
.position-sticky{
    position:sticky;
    width: 250px;
    height: 100%;
    top: 72px;
    /*left: 0;*/
}

.content-box{
    /*height: 94px;*/
    margin-top: 4px;
    /*margin-bottom: 20px;*/
    padding: 0 16px;
}

.el-tabs__nav-wrap:after{
    content:'';
    background-color: transparent !important;
}

.repo-Data{
    padding: 16px;
    background-color: #FFFFFFB2 ;
}
.repo-tabs{
    height: 32px;
    width: 100%;
    margin-bottom: 16px;
    z-index: 10;
}
.modelTabs .el-tabs__header.is-top{
    margin: 0;
}

.specialShowHead{
    height: 312px;
    padding-top: 48px;
}

.specialShowHeadBack{
    width: 100%;
    height: 328px;
    background: url("../images/specialBack.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: -16px;
    z-index: 1;
}

.filterableSelect .el-select__selection{
    min-height: 48px;
}

@media screen and (min-width: 1920px) {
    .h-full {
        height: 100%;
        width: 1760px;
        margin: 0 auto;
        position: relative;
        /* background-color: #fff; */
    }
    .navbar{
        width: 1760px;
        margin: 0 auto;
        z-index: 5;
    }
    .setWidth{
        width: calc(50% - 8px);
    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .h-full {
        height: 100%;
        width: calc(100% - 160px);
        margin: 0 80px;
        z-index: 5;
        position: relative;
        /* background-color: #fff; */
    }
    .navbar{
        width: calc(100% - 160px);
        margin: 0 80px;
        z-index: 5;
    }
    .setWidth{
        width: calc(50% - 8px);
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .h-full {
        height: 100%;
        width: 100%;
        background-color: #fff;
        position: relative;
    }
    .navbar{
        width: calc(100% - 16px);
        margin-left: 0 !important;
        margin-right: 16px !important;
        z-index: 99;
        /*margin: 0 16px;*/
    }
    .setWidth{
        width: 100%;
    }
}