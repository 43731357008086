
.select[data-v-c069174a] {
  width: 320px;
}
.selectButton[data-v-c069174a] {
  width: 100%;
  padding: 8px;
}
.selectButton[data-v-c069174a] span {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.selectClass[data-v-c069174a]{
  height: 32px;
}
.selectClass[data-v-c069174a] .el-select__wrapper.el-tooltip__trigger.el-tooltip__trigger{
  min-height: auto;
  gap: 0;
}
