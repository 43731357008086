
[data-v-d4e23c68] .row-item-clamp .cell {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
[data-v-d4e23c68] .el-table__header-wrapper th {
    background-color: #F9FAFB;
}
