
[data-v-58407767] .el-input {
  height: 40px;
@media screen and (max-width: 768px) {
    width: 100%;
}
}
[data-v-58407767] .el-radio__input {
  margin-top: 4px;
}
[data-v-58407767] .el-radio__label {
  color: #344054 !important;
  font-weight: 400;
}
[data-v-58407767] .el-radio.is-bordered.is-checked {
  border: 2px solid #3250BD;
}
[data-v-58407767] .el-radio__input.is-checked .el-radio__inner {
  background: #3250BD;
  border-color: #3250BD;
}
[data-v-58407767] .el-select {
  width: 240px;
  height: 40px;
@media screen and (max-width: 768px) {
    width: 100%;
}
}
[data-v-58407767] .el-input .el-input__wrapper {
  border-radius: 8px;
}
.modelSelect[data-v-58407767] {
  width: 100%;
}
.modelSelect[data-v-58407767] .el-select__wrapper {
  box-shadow: 0 0 0 1px var(--el-border-color);
}
