
.noData[data-v-96c06e75]{
  width: 100%;
  height: calc(100vh - 272px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.text[data-v-96c06e75]{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
