
.pagination[data-v-1f76c710]{
  width: 100%;
  padding: 16px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
  z-index: 5;
  height: 48px;
  //position: absolute;
  //bottom: 0;
  //left: 0;
}
.pagination-item[data-v-1f76c710]{
  --el-pagination-hover-color: #2047D3;
}
.pagination-item[data-v-1f76c710] .el-pager{
  gap: 8px;
  margin: 0px 8px;
}
.pagination-item[data-v-1f76c710] .el-pager .number{
  color: #6B7785;
}
.pagination-item[data-v-1f76c710] .el-pager .number:hover{
  color: #2047D3;
}
.pagination-item[data-v-1f76c710] .el-pager .is-active{
  color: #2047D3;
  background-color: #E8F1FF;
  font-weight: 600;
}

