
.timeline-line{
    margin:10px 12px 0;
    height:8px;
    width:1px;
    background:#DCDFE6;
}
  /* .timeline-item::before {
    content: '|';
    margin-right: 0.5rem;
  }
  .timeline-item.first-item::before {
    content: none; 
  } */
.timeline-item.first-item .timeline-line {
    display:none
}
