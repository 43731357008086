
.email[data-v-ffe481de]{
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 设置显示的行数 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
