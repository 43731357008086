
.lable-word[data-v-6502951c] {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1D2129;
}
.endpoint-word[data-v-6502951c] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  display: flex;
  align-items: center;
}
.cursor-pointer[data-v-6502951c] {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}
.cursor-pointer img[data-v-6502951c] {
  width: 100%;
  height: 100%;
}
.tiem-word[data-v-6502951c] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #A9AEB8;
}
.headerTag[data-v-6502951c] {
  height: 23px;
  margin: 20px 0;
}
.spacing[data-v-6502951c] {
  background: #E5E6EB;
  height: 14px;
  width: 1px;
  margin: 0 8px;
}
.rollLable-word[data-v-6502951c] {
  margin-right: 8px;
}
.rollHeaderBox[data-v-6502951c] {
  width: 100%;
  white-space: nowrap;
  /* 禁止文本换行 */
  overflow: hidden;
  /* 隐藏溢出部分 */
  text-overflow: ellipsis;
  display: flex;
  height: 32px;
  align-items: center;
  margin-bottom: 16px;
}
.codeHearder[data-v-6502951c]{
  margin-bottom: 16px;
}
.licenseTag[data-v-6502951c]{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 2px 8px;
  background-color: rgb(232, 241, 255);
  color: rgb(32, 71, 211);
  border-radius: 2px;
}
.licenseTagBox[data-v-6502951c]{
  height: 23px;
  margin: 20px 0;
}
