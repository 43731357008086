
.botton-box[data-v-c701e9ba]{
  height: 56px;
  background-color: transparent;
  z-index: 5;
  margin-top: 16px;
}
.margin32[data-v-c701e9ba]{
  margin: 0 32px;
}
.font[data-v-c701e9ba]{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1D2129;
}
.jgwb[data-v-c701e9ba]{
  display: flex;
  align-items: center;
}
