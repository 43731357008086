
[data-v-9b099338] .cm-editor {
    border-radius: 4px;
}
[data-v-9b099338] .cm-gutters {
    min-height: 200px !important;
}
[data-v-9b099338] .cm-focused {
    outline: none;
}
[data-v-9b099338] .el-tabs__content {
    padding: 0;
}
[data-v-9b099338] .el-tabs__header {
    background-color: #F5F7FA !important;
}
[data-v-9b099338] .d2h-file-header {
    background-color: #F5F7FA !important;
}
