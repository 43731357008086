
@media (max-width: 768px) {
.InviteMember .invite_dialog{
      width:350px;
}
}
.InviteMember .scroll-container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 8px; /* 滚动条宽度 */
    background-color: #ccc; /* 滚动条颜色 */
    border-radius: 4px; /* 滚动条圆角 */
}
.InviteMember .scroll-container .content {
    padding-right: 8px; /* 留出滚动条的空间 */
}
.InviteMember .scroll-container::-webkit-scrollbar {
    width: 8px; /* 滚动条宽度 */
}
.InviteMember .scroll-container::-webkit-scrollbar-thumb {
    background-color: #888; /* 滚动条thumb颜色 */
    border-radius: 4px; /* 滚动条thumb圆角 */
}
