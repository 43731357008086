
.icon[data-v-d18fc2a2] {
  background: linear-gradient(90deg, rgba(91, 143, 249, 0.6) 0%, rgba(149, 128, 249, 0.6) 100%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.industrTag[data-v-d18fc2a2] {
  display: flex;
  padding: 20px 16px;
  border-bottom: 1px solid #E5E6EB;
  gap: 16px;
  position: relative;
  cursor: pointer;
}
.industrTag[data-v-d18fc2a2]:hover::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #2047D3 0%, #925FFF 100%);
}
.activationTag[data-v-d18fc2a2] {
  border: none;
}
.activationTag[data-v-d18fc2a2]::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #2047D3 0%, #925FFF 100%);
}
.industrData[data-v-d18fc2a2] {}
.industrLable[data-v-d18fc2a2] {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #1D2129;
}
.industrNumber[data-v-d18fc2a2] {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6B7785;
}
.activationLable[data-v-d18fc2a2] {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1D2129;
}
