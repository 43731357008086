
.dataset-card:hover .dataset-path[data-v-4c7bf049] {
  color: var(--blue-blue-5001-f-75-cb, #1F75CB);
}
.model-card:hover .model-path[data-v-4c7bf049] {
  color: var(--theme-dark-red-t-red-500-ad-4-a-3-b, #AD4A3B);
}
.repoCardClass[data-v-4c7bf049] {
  height: 246px;
  border-radius: 2px;
  display: inline-block;
  box-shadow: 0px 2px 4px 0px #001C4D08;
  border: 1px solid #F2F3F5;
  background: #FFFFFF;
  position: relative;
}
.repoCardClass[data-v-4c7bf049]:hover {
  border-bottom: 4px solid #2047D3;
}
.repoCardClass[data-v-4c7bf049]:focus {
  border-bottom: 4px solid #2047D3;
}
.repoCardTitle[data-v-4c7bf049] {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #1D2129;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.repoCardPath[data-v-4c7bf049]{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.repoCardDescription[data-v-4c7bf049] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #6B7785;
  height: 44px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.formatTaskTags[data-v-4c7bf049] {
  width: 100%;
  height: 24px;
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
}
.time[data-v-4c7bf049] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  //line-height: 32px;
  text-align: left;
  color: #A9AEB8;
  //padding: 0px 16px;
}
.popoverCard[data-v-4c7bf049] {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.popperClass[data-v-4c7bf049]{
  border-radius: 4px;
  border-top: none;
}
.tagBottm[data-v-4c7bf049]{
  width:100%;
  height: 32px;
  display: flex;
  align-items: center;
}
.tagLogo[data-v-4c7bf049]{
  height: 100%;
  width: 73px;
}
.tagLogo img[data-v-4c7bf049]{
  width: 100%;
  height: 100%;
}
.spacing[data-v-4c7bf049]{
  height: 14px;
  width:1px;
  background-color: #E5E6EB;
  margin: 0 16px;
}
.download[data-v-4c7bf049],.Collection[data-v-4c7bf049]{
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  color: #A9AEB8;
  display: flex;
  align-items: center;
}
.icon[data-v-4c7bf049]{
  margin-right: 2px;
}
.licenseTag[data-v-4c7bf049]{
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 2px 8px;
  background-color: rgb(232, 241, 255);
  color: rgb(32, 71, 211);
  border-radius: 2px;
}
