
.button-box[data-v-38f933cb] {
  width: 450px;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.content-box[data-v-38f933cb] {
  width: calc(100% - 450px);
}
